body {
    font-family: 'Catamaran', sans-serif;
    overflow-x: hidden;
}

.navbar {
    padding-left: 2rem;
    padding-right: 2rem;

    .navbar-text {
        display: none;
    }

    .navbar-brand {
        img {
            width: 14rem;
        }
    }
}

.annotated {
    font-weight: 400;

    p, a {
        margin: 0;
        padding: 0;

        font-size: 0.8rem;
        line-height: 0.6rem;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .anot-left,
    .anot-right {
        border-bottom: 1px black solid;
    }

    .anot-right {
        padding-right: 3rem;
    }

    .anot-left {
        padding-left: 3rem;
    }
}

#heart,
#hospital,
#heart-example {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 80vh;

    cursor: url("../img/icn-rotate.svg") 26 13, auto;
}

#section-heart {
    * {
        margin: 0;
        padding: 0;
    }

    .col-12 {
        width: 100%;
    }
}

#heart-iframe {
    .annotated {
        a h2 {
            line-height: 0;
        }

        .annot-left,
        .annot-right {
            border-bottom: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.hotspot-threesixty {
    position: absolute;
    display: inline-block;
    min-height: 60px;
    min-width: 60px;

    background-image: url("../img/nice/pulse-icon.png");
    background-size: cover;
}

.swipe-helper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    img {
        bottom: 2rem;
        left: 2rem;
        display: inline-block;
        position: absolute;
        width: 8rem;
        height: 8rem;
    }
}