@include media-breakpoint-up(sm) {
    .navbar {
      .navbar-text {
        display: block;
      }
    }

    p {
      font-size: 0.9rem;
    }
}